.Loading {
  font-size: 25px;
  margin: 24px;
}
.LandingView {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100vh;
}
.Home__card {
  position: relative;
}

.Home__add {
  position: absolute;
  top: 15px;
  right: 15px;
}

.HomeCategory__tag {
  margin-bottom: 6px;
}

.Home__add-tour {
  opacity: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.Home__tour-item:hover .Home__add-tour,
.Home__add-tour:focus {
  opacity: 1;
}
.ProfileView {
  max-width: 480px;
  margin: 0 auto;
}

.ProfileView__top {
  text-align: center;
}

.ProfileView__name {
  font-size: 20px;
  margin-top: 8px;
}

.ProfileView__upload .ant-upload.ant-upload-select-picture-card {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  margin: 0 auto;
}
.SiteView {
  max-width: 968px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
}

.SiteView__content .ant-tabs-bar {
  text-align: center;
}

.SiteView__title {
  color: var(--primary-color);
  font-size: 32px;
}

.SiteView__label {
  color: #969696;
  font-size: 12px;
  text-align: left;
}

.SiteView__text {
  font-size: 16px;
  margin-top: 4px;
  text-align: left;
}
.NewTourView {
  margin-top: 3em;
}

.NewTourView__title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: var(--primary-color);
}

.NewTourView__desc-title {
  color: white;
  font-size: 44px;
  font-weight: 800;
  margin-bottom: 0;
}

.NewTourView__desc-text {
  color: #ededed;
}

.NewTourView__plus {
  width: 45px;
  height: 45px;
}

.NewTourView__assignments {
  margin-top: 2em;
}
/* The device with borders */
.PhonePreview {
  position: relative;
  width: 220px;
  height: 360px;
  margin: auto;
  border: 16px white solid;
  border-top-width: 30px;
  border-bottom-width: 15px;
  border-radius: 18px;
}

/* The horizontal line on the top of the device */
.PhonePreview:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -15px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The screen (or content) of the device */
.PhonePreview__content {
  width: 100%;
  height: 100%;
  background: white;
  overflow: auto;
}
.SitePreview {
  position: relative;
  padding: 0;
}

.SitePreview__text {
  position: absolute;
  left: 5px;
  bottom: 10px;
  margin: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}

.SitePreview__image {
  height: 80px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.SitePreview::before {
  content: '';
  background-color: #00000059;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.ListItem {
  margin-bottom: 10px;
  padding: 15px;
  background: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.ListItem:hover {
  border: 1px solid var(--primary-color) !important;
  -webkit-box-shadow: 1px 2px 5px 2px #00000014;
          box-shadow: 1px 2px 5px 2px #00000014;
}

.ListItem .ant-list-item-meta-title {
  font-size: 16px;
}

.ListItem .ant-list-item-meta-description,
.ListItem .ant-list-item-meta-description p {
  margin-bottom: 0;
}
.Header {
  padding: 0 16px;
  background: white;
}
.SiderMenu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: calc(100vh - 84px);
}

.SiderMenu .ant-menu-item-selected {
  background-color: transparent !important;
}

.SiderMenu .ant-menu-item-selected:after {
  top: 5px;
  bottom: 5px;
  opacity: 1;
  -webkit-transform: none;
          transform: none; 
}
:root {
  --primary-color: #e21f2a;
}

/* Fix heading selector */
.ql-snow .ql-picker {
  line-height: 24px;
}

.ql-editor {
  background-color: white;
  min-height: 300px;
}
.leaflet-draw-draw-polyline {
  background: var(--primary-color) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADsQAAA7EB9YPtSQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACESURBVCiR7ZKxDQJBDATnHmgBBAkBFVAaHUEnJ30DHyBEggipgIBoSPziQ59E+Js4ml1bXmiQWp2oa4GBBXABTgDLhtQ1cAT2U6cMuAEqsANWwAd4phLVa5x5V7fpVWfwn2BUrlffAd7ir6lE1bP6CIPcqvzqOcQ8lFJeWXhMHlXTIPAFS0DQ6iAOQPIAAAAASUVORK5CYII=) no-repeat center !important;
}
.leaflet-draw-draw-polygon {
  background: var(--primary-color) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAELgAABC4BL4L3AQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADVSURBVDiNnZM9TkJREEbPPChYhYWVK3AXFibY0tlZmFjRWNC6Bjo3YGFHQmMtMQ8NJmyE+HMoAPN4coH7vmS6OSe5d2YgEXWgvqgXqZ5k1Du386peqXEMfGs6b2pPbaXgG/V3j2CT6VrUrsLXR8LVlBu4p/5kwqofhdoFhkCR/dswL4A+0D7UuU9w3xBeCSLiGRg3FQCgnjeYgOrpn0p9yoQX1vbgTP3KEMygMrqImAGPue/fOg71BPgEOjuABfAOTNc1iojJv+tSH4BLoKzVPCK+6/1L97qumkUa3DoAAAAASUVORK5CYII=) no-repeat center !important;
}

.leaflet-draw-draw-polyline,
.leaflet-draw-draw-polygon {
  width: 36px!important;
  height: 36px!important;
}
html {
  overflow: auto;
}

body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  background-color: rgba(#f5f5f5, 0.6);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  /* border-radius: 6px; */
  box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  background-color: #555;
}

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

.-x-debug {
  background: rgba(0, 0, 0, 0.1) !important;
}

.-x-debug-border {
  border: dashed 1px rgba(0, 0, 0, 0.1) !important;
}

/**/

.-x-relative {
  position: relative;
}

.-x-fit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.-x-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.-x-fixed-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 64px;
  /* margin-top: 56px; */
}

.-fill-height {
  /* align-items: center;
  display: flex; */
  max-height: 100%;
}

.-fill-width {
  width: 100%;
}


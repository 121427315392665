.SiteView {
  max-width: 968px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SiteView__content .ant-tabs-bar {
  text-align: center;
}

.SiteView__title {
  color: var(--primary-color);
  font-size: 32px;
}

.SiteView__label {
  color: #969696;
  font-size: 12px;
  text-align: left;
}

.SiteView__text {
  font-size: 16px;
  margin-top: 4px;
  text-align: left;
}
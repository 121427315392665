.SitePreview {
  position: relative;
  padding: 0;
}

.SitePreview__text {
  position: absolute;
  left: 5px;
  bottom: 10px;
  margin: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}

.SitePreview__image {
  height: 80px;
  width: 100%;
  object-fit: cover;
}

.SitePreview::before {
  content: '';
  background-color: #00000059;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
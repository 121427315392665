.NewTourView {
  margin-top: 3em;
}

.NewTourView__title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: var(--primary-color);
}

.NewTourView__desc-title {
  color: white;
  font-size: 44px;
  font-weight: 800;
  margin-bottom: 0;
}

.NewTourView__desc-text {
  color: #ededed;
}

.NewTourView__plus {
  width: 45px;
  height: 45px;
}

.NewTourView__assignments {
  margin-top: 2em;
}
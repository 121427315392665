.ProfileView {
  max-width: 480px;
  margin: 0 auto;
}

.ProfileView__top {
  text-align: center;
}

.ProfileView__name {
  font-size: 20px;
  margin-top: 8px;
}

.ProfileView__upload .ant-upload.ant-upload-select-picture-card {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  margin: 0 auto;
}
.leaflet-draw-draw-polyline {
  background: var(--primary-color) url('../assets/polyline-light.png') no-repeat center !important;
}
.leaflet-draw-draw-polygon {
  background: var(--primary-color) url('../assets/polygone-light.png') no-repeat center !important;
}

.leaflet-draw-draw-polyline,
.leaflet-draw-draw-polygon {
  width: 36px!important;
  height: 36px!important;
}
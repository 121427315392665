/* The device with borders */
.PhonePreview {
  position: relative;
  width: 220px;
  height: 360px;
  margin: auto;
  border: 16px white solid;
  border-top-width: 30px;
  border-bottom-width: 15px;
  border-radius: 18px;
}

/* The horizontal line on the top of the device */
.PhonePreview:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The screen (or content) of the device */
.PhonePreview__content {
  width: 100%;
  height: 100%;
  background: white;
  overflow: auto;
}
@import './styles/lists.css';
@import './styles/header.css';
@import './styles/sider.css';
@import './styles/variables.css';
@import './styles/quill.css';
@import './styles/map.css';

html {
  overflow: auto;
}

body {
  user-select: none;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  background-color: rgba(#f5f5f5, 0.6);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  /* border-radius: 6px; */
  box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  background-color: #555;
}

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

.-x-debug {
  background: rgba(0, 0, 0, 0.1) !important;
}

.-x-debug-border {
  border: dashed 1px rgba(0, 0, 0, 0.1) !important;
}

/**/

.-x-relative {
  position: relative;
}

.-x-fit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.-x-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.-x-fixed-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 64px;
  /* margin-top: 56px; */
}

.-fill-height {
  /* align-items: center;
  display: flex; */
  max-height: 100%;
}

.-fill-width {
  width: 100%;
}

.SiderMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 84px);
}

.SiderMenu .ant-menu-item-selected {
  background-color: transparent !important;
}

.SiderMenu .ant-menu-item-selected:after {
  top: 5px;
  bottom: 5px;
  opacity: 1;
  transform: none; 
}
.Home__card {
  position: relative;
}

.Home__add {
  position: absolute;
  top: 15px;
  right: 15px;
}

.HomeCategory__tag {
  margin-bottom: 6px;
}

.Home__add-tour {
  opacity: 0;
  transition: all .3s;
}

.Home__tour-item:hover .Home__add-tour,
.Home__add-tour:focus {
  opacity: 1;
}
.ListItem {
  margin-bottom: 10px;
  padding: 15px;
  background: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.ListItem:hover {
  border: 1px solid var(--primary-color) !important;
  box-shadow: 1px 2px 5px 2px #00000014;
}

.ListItem .ant-list-item-meta-title {
  font-size: 16px;
}

.ListItem .ant-list-item-meta-description,
.ListItem .ant-list-item-meta-description p {
  margin-bottom: 0;
}